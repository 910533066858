const Filter = {
    "filters": document.querySelectorAll(".js-filter"),

    "initFilters": function () {
        this.filters.forEach(filter => {
            if (filter.classList.contains('js-alphabeticalFilter')) {
                this.initAlphabeticalFilter(filter);
            }
        });
    },

    "initAlphabeticalFilter": function (filter) {
        let filteredList = document.querySelector(`.${filter.dataset.filters}`);
        let listItems = filteredList.querySelectorAll('.js-elementToFilter');

        filter.addEventListener('input', (event) => this.filterList(event, listItems));

    },

    "filterList": function (e, listItems) {
        let text = e.target.value.toUpperCase();

        let shownElements = [...listItems].filter(el => {
            let textContent = el.getElementsByTagName('a')[0].textContent || el.getElementsByTagName('a')[0].innerText();

            if (textContent.toUpperCase().indexOf(text) > -1) {
                return el;
            }
        });

        this.showFilteredList(listItems, shownElements);
    },

    "showFilteredList": function (listItems, shownElements) {
        listItems.forEach(item => {

            if (!shownElements.includes(item)) {
                item.style.display = "none";
            } else {
                item.style.display = "block";
            }
        });

        this.checkIfAccordionItemIsEmpty();
    },

    "checkIfAccordionItemIsEmpty": function () {
        let containers = document.querySelectorAll('.js-filteredElementContainer');

        containers.forEach(container => {
            let elements = [...container.querySelectorAll('.js-elementToFilter')];

            let shownElements = elements.filter(el => el.style.display === "block");

            container.style.display = shownElements.length ? "block" : "none";

        });

        $('.js-filteredElementContainer:visible').first().find('.collapse').collapse('show');
    }

};

export default Filter;