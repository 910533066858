import '../scss/voyageurs.scss';
import './common.js';
import $ from "jquery";

import Forms from './forms';
import DatePicker from './datepicker';
import SelectForms from './select-forms';
import Common from "./common";
import Filter from "./filter";


let equalTabHeight = {
    initEqualTabHeight: function () {
        let self = this;


        if ($(window).innerWidth() >= Common.mqBreakpoints.md) {
            let tabsContainers = $('.js-sameHeightTabs-container');

            tabsContainers.each(function () {
                self.setEqualTabHeight($(this));
            });

            $('.js-sameHeightTabs-links').on('shown.bs.tab', function () {
                let tabContainer = $(this).closest('.js-sameHeightTabs-container');
                self.setEqualTabHeight(tabContainer);
            });
        } else {
            $('.js-sameHeightTabs').css('height', 'auto');

            $('.js-sameHeightTabs-links').on('shown.bs.tab', function (e) {
                $('.js-sameHeightTabs').css('height', 'auto');
            });
        }
    },

    setEqualTabHeight: function (tabContainer) {
        let alltabs = tabContainer.find('.js-sameHeightTabs');
        let maxSize = 0;

        alltabs.each(function () {
            $(this).height("auto");
            maxSize = $(this).height() > maxSize ? $(this).height() : maxSize;
        });

        alltabs.each(function () {
            $(this).height(maxSize);
        });
    },
};


$(window).resize(function () {
    equalTabHeight.initEqualTabHeight();
});

$(document).ready(function () {
    Forms.initForm();
    equalTabHeight.initEqualTabHeight();
    DatePicker.initInlineTimePicker();
    SelectForms.selectInputInit();
    Filter.initFilters();
    $('[data-toggle="tooltip"]').tooltip();
});

