import $ from "jquery";
import Common from './common.js';
import Autocomplete from "./autocomplete.js";

const Forms = {

    "initForm": function () {
        this.selectFormsInit();
        this.initViaBtn();
        this.selectAllCheckboxes();
        this.uncheckSelectAllBox();
        this.initSwitchButton();
        this.initBulletsStates();
    },

    "selectFormsInit": function () {
        let self = this;
        const selectItems = $('.js-select');

        if ($(selectItems)) {
            $(selectItems).each(function () {
                let $thisBtn = $(this).closest('.dropdown').find('.dropdown-toggle span');

                $(this).on("click", function () {
                    self.changeSelectButtontext($(this), $thisBtn)
                });
            });
        }
    },

    "changeSelectButtontext": function (elem, btn, text) {
        let additionaltext = $(window).innerWidth() >= Common.mqBreakpoints.md ? text : false;

        if (additionaltext) {
            btn.text(elem.text() + text);
        } else {
            btn.text(elem.text());
        }

    },

    "initViaBtn": function () {
        let self = this;
        let viaBtn = $('.js-addViaBtn');

        viaBtn.on("click", function () {
            let viaInputs = $(this).closest('.form-via').find('.js-inputSelect');
            self.addViaStep($(this), viaInputs.length);
            self.initRemoveViaBtn();
        });
    },

    "addViaStep": function (input, idNumber) {
        let template = $($('#viaSteps').html());
        let addedInput = template.find('.js-adress');
        let templateId = '#' + addedInput.attr('id');

        let errorMessage = input.closest('.form-via').find('.js-error');

        if (this.checkIfBeforeViaIsPopulated(input)) {
            $('.via-error').remove();

            if ($('.form-via-step').length < 1) {
                $('.form-via-btn').hide();
                $(template).insertBefore(input);
                let url =  $(templateId)[0].dataset['url'];
                Autocomplete.fetchResults(url, $(templateId)[0]);
                Autocomplete.autoCompleteHandler($(templateId)[0]);
                $('.form-via-btn').show();
            }

            if ($('.form-via-step').length === 1) {
                $('.form-via-btn').hide();
            }
        } else if (!errorMessage.length) {
            this.appendErrorMessage(input);
        }
    },

    "checkIfBeforeViaIsPopulated": function (input) {
        let prevViaGroup = input.prev();
        let adressInput = prevViaGroup.find('.js-adress');

        if (adressInput.val() === "") {
            return false;
        }

        return true;
    },

    "appendErrorMessage": function (input) {
        let template = $($('#viaError').html());
        $(template).insertAfter(input);
    },

    "initRemoveViaBtn": function () {
        $(".form-via-remove-btn").on('click', function (e) {
            e.preventDefault();
            $(this).closest('.form-via-step').remove();

            if ($('.form-via-step').length < 1) {
                $('.form-via-btn').show();
            }
        });
    },

    //when the selectAllCheckbox is checked, all the other checkboxes in the fieldset are checked
    "selectAllCheckboxes": function () {
        $('.js-selectAllCheckboxes').on('change', function () {
            let fieldset = $(this).closest('.fieldset');
            let checkboxes = $(fieldset).find(' .custom-control-input');

            if (!$(this).prop('checked')) {
                $(this).prop('checked', false);
                $(checkboxes).prop('checked', false);
            } else {
                $(checkboxes).prop('checked', true);
            }

        });
    },
    //when one of the checkbox in the fielset is not checked, unchecks the selectAllCheckbox
    //if all checboxes are checked, checks the selectAllCheckbox
    "uncheckSelectAllBox": function () {
        if ($('.js-selectAllCheckboxes').length) {
            $('.custom-control-input').on('change', function (e) {
                let fieldset = $(this).closest('.fieldset');
                let selectAllCheckBox = $(fieldset).find('.js-selectAllCheckboxes');
                let checkboxes = $(fieldset).find(' .custom-control-input:not(".js-selectAllCheckboxes")')
                let checkedCheckboxes = $(checkboxes).filter(function () {
                    return $(this).prop('checked') === true;
                });

                if (checkboxes.length > checkedCheckboxes.length) {
                    $(selectAllCheckBox).prop('checked', false);
                } else {
                    $(selectAllCheckBox).prop('checked', true);
                }
            });
        }
    },

    "initSwitchButton": function () {
        $('.js-switch-values').on('click', function () {
            let container = $(this).parent();

            let departureGroup = container.find('.js-departure');
            let arrivalGroup = container.find('.js-arrival');

            let departureAdress = departureGroup.find('.js-adress');
            let departureId = departureGroup.find('.js-trainId');
            let departurePlaceholder = departureGroup.find('.select2-selection__rendered');

            let arrivalAdress = arrivalGroup.find('.js-adress');
            let arrivalId = arrivalGroup.find('.js-trainId');
            let arrivalPlaceholder = arrivalGroup.find('.select2-selection__rendered');
            let arrivalAdressVal = arrivalAdress.val();
            let arrivalIdVal = arrivalId.val();

            if (arrivalAdressVal.length && departureAdress.val().length) {
                arrivalAdress.val(departureAdress.val());
                arrivalId.val(departureId.val());
                arrivalPlaceholder.text(departureAdress.val());

                departureAdress.val(arrivalAdressVal);
                departureId.val(arrivalIdVal);
                departurePlaceholder.text(arrivalAdressVal)
            }
        });
    },

    "initBulletsStates": function () {
        let bullets = [...document.querySelectorAll('[name="departureBullets"')];
        bullets.forEach(bullet => {
            bullet.addEventListener('change', this.updateBulletsStates);
        });
    },

    "updateBulletsStates": function (e) {
        const target = e.target;
        const classNames = ["js-departureBullet", "js-arrivalBullet"];
        let targetClass = classNames.find(className => {
            if (target.classList.contains(className)) {
                return className;
            }
        });

        let bullets = document.getElementsByClassName(targetClass);
        bullets.forEach(bullet => bullet.checked = true);
    },

};

export default Forms;